<template>
  <div class="productsStatistics">
    <div class="title">
      <div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from.member_mobile"
            placeholder="请输入推荐人手机号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.member_name"
            placeholder="请输入推荐人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <span class="span">供货周期：</span>
          <el-date-picker
      v-model="datevalue"
      type="daterange"
      @change="createChange"
        value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.member_label_ids"
            multiple
            collapse-tags
            filterable
            clearable
            placeholder="选择标签"
          >
            <el-option
              v-for="item in memberlabelalllist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="share_date" align="center" label="供货周期">
        </el-table-column>
        <el-table-column prop="member_name" align="center" label="推荐人">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="推荐人手机号">
        </el-table-column>
        <el-table-column prop="share_user_num" align="center" label="查看人次">
        </el-table-column>
        <el-table-column
          prop="purchase_user_num"
          align="center"
          label="实际购买人次"
        >
        </el-table-column>
        <el-table-column prop="qty" align="center" label="实际购买件数">
        </el-table-column>
        <el-table-column prop="share_rate" align="center" label="推品成功率">
          <template slot="header" slot-scope="scope">
            推品成功率
            <el-tooltip
              class="item"
              effect="dark"
              content="实际购买人次/查看人次*100%；"
              placement="top"
            >
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="logistics_qty_txt"
          align="center"
          label="下单城市仓"
        >
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="seeinfo(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
      <script>
import { postExportgroupdata } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { formatDate } from "@/utils/tools/date.js";
export default {
  name: "productsStatistics",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        start_date: formatDate(), //	string	开始日期
        end_date: formatDate(), //	string	结束日期
        member_name: "", //	string	用户名
        member_mobile: "", //	string	用户手机号
        member_label_ids: [], //	number []	用户标签id数组
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      memberlabelalllist: [],
      datevalue:[formatDate(),formatDate()],
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getmemberlabelall();
  },
  methods: {
    createChange(val) {
      console.log(val);
      if (val) {
        this.from.start_date = val[0];
        this.from.end_date = val[1];
      } else {
        this.from.start_date = formatDate();
        this.from.end_date = formatDate();
        this.datevalue = [formatDate(),formatDate()];
      }
    },
    getmemberlabelall() {
      this.$api.member.memberlabelall().then((res) => {
        console.log(res);
        this.memberlabelalllist = res.data;
      });
    },

    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      postExportgroupdata(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },

    hqlist() {
      this.loading = true;
      this.$api.share
        .getsharemembergrouplist(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    seeinfo(e) {
      this.$router.push({
        path: "/productsDetails",
        query: {
          mobile: e.mobile,
          share_date: e.share_date,
        },
      });
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.datevalue = [formatDate(),formatDate()];
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
      <style lang="scss">
.productsStatistics {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        .span{
width: 100px;
        }
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
      
    